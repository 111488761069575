<template>
  <div class="payH5 orderPay">
    <div class="page-top">
      <div class="back" @click="goBack">
        <img src="@/assets/images/mycard/arrow-down.png" alt="" />
      </div>
      <span>{{ $t("myCard.orderTitle") }}</span>
    </div>
    <div class="order-main">
      <div class="title">
        <h3>{{ $t("myCard.coupon") }}</h3>
        <div class="more" @click="showPop = true">
          <i v-if="showPoint"></i>
          <span v-if="!couponList || couponList.length === 0">{{
            $t("myCard.noCoupon")
          }}</span>
          <span v-else>{{ $t("myCard.check") }}</span>
          <img src="@/assets/images/mycard/arrow-down.png" alt="" />
        </div>
      </div>

      <div class="choose-info">
        <p>
          <span>{{ $t("myCard.proInfo") }}</span>
          <span>{{
            pointNum && pointNum > 0
              ? $t("myCard.extraTitle")
              : chooseProduct.product_subject
          }}</span>
        </p>
        <p>
          <span>{{ $t("myCard.roleInfo") }}</span>
          <span>{{
            chooseRoleInfo.server + " - " + chooseRoleInfo.role_name
          }}</span>
        </p>
        <p>
          <span>{{ $t("myCard.proPrice") }}</span>
          <span class="active"
            >{{
              pointNum && pointNum > 0
                ? pointCustom.currency_code
                : chooseProduct.currency_code
            }}{{
              pointPrice && pointNum > 0
                ? pointPrice
                : chooseProduct.price
                ? (chooseProduct.price / 100).toFixed(2)
                : 0
            }}</span
          >
        </p>
        <p>
          <span>{{ $t("myCard.disCountPrice") }}</span>
          <span class="active" v-if="hasChooseCouponList.length > 0">
            -{{
              pointNum
                ? pointCustom.currency_code
                : chooseProduct.currency_code
            }}{{ disCountPrice }}
          </span>
          <span v-else>-</span>
        </p>
      </div>
      <div class="title">
        <h3>{{ $t("myCard.titlePayWay") }}</h3>
      </div>
      <div class="pay-type">
        <span
          v-for="(item, index1) in payWays"
          :key="index1"
          :class="payWay === item.name ? 'active' : ''"
          @click="choosePayWay(item)"
        >
          <img
            v-if="item.type === '11'"
            src="@/assets/images/mycard/mycard.png"
            alt=""
          />
          <img
            v-if="item.type === '12'"
            src="@/assets/images/mycard/gash.png"
            alt=""
          />
          <img
            v-if="item.type === '15'"
            src="@/assets/images/mycard/paypal.jpeg"
            alt=""
          />
          <img
            v-if="item.type === '22'"
            src="@/assets/images/mycard/payletter.png"
            alt=""
          />
          <div
            class="pay-letter"
            v-if="payWay === 'payletter' && item.type === '22'"
          >
            <span
              v-for="(item2, index4) in payLetterList"
              :key="index4"
              :class="[
                pg_code === item2.id ? 'active' : '',
                item2.id === 'creditcard' ? 'creditcard' : '',
              ]"
              @click="pg_code = item2.id"
            >
              <i></i>
              <em v-if="item2.id === 'creditcard'">신용카드/체크카드</em>
              <img :src="item2.img" alt="" />
            </span>
          </div>
        </span>
      </div>
    </div>
    <div class="fix-bottom">
      <span>
        {{ $t("myCard.totalPrice") }}
        <i v-if="hasChooseCouponList.length > 0">
          {{ pointCustom.currency_code || chooseProduct.currency_code || "" }}
          {{
            pointPrice
              ? pointPrice - disCountPrice >
                minPrices[pointCustom.currency_code_city] / 100
                ? (pointPrice - disCountPrice).toFixed(2)
                : minPrices[pointCustom.currency_code_city] / 100
              : chooseProduct.price
              ? chooseProduct.price / 100 - disCountPrice >
                minPrices[chooseProduct.currency_code_city] / 100
                ? (chooseProduct.price / 100 - disCountPrice).toFixed(2)
                : minPrices[chooseProduct.currency_code_city] / 100
              : 0
          }}
        </i>
        <i v-else>
          {{ pointCustom.currency_code || chooseProduct.currency_code || "" }}
          {{
            pointNum && pointNum > 0
              ? pointPrice
              : chooseProduct.price
              ? (chooseProduct.price / 100).toFixed(2)
              : 0
          }}
        </i>
      </span>
      <a
        :class="['login-btn', payWay && payWay.length > 0 ? '' : 'disabled']"
        @click="submit()"
        >{{ $t("myCard.submit") }}</a
      >
    </div>

    <div class="popup" v-show="showPop" @click="showPop = false">
      <div class="popup-main" @click.stop="showPop = true">
        <div class="popup-title">
          <div class="back" @click.stop="showPop = false">
            <img src="@/assets/images/mycard/arrow-down.png" alt="" />
          </div>
          <span>{{ $t("myCard.chooseCoupon") }}</span>
          <span class="exchange-btn" @click="showExchange = true">{{
            $t("myCard.exchangeCoupon")
          }}</span>
        </div>
        <div class="coupon-list" v-if="couponList.length > 0">
          <div
            :class="[
              'each',
              'flex',
              item.disabled ? 'disabled' : '',
              item.active === 1 ? 'active' : '',
            ]"
            v-for="(item, index) in couponList"
            :key="index"
            @click.stop="chooseCoupon(item)"
          >
            <p
              v-if="
                item.max_discount_price &&
                item.max_discount_price[couponCurrentCode]
              "
            >
              {{ $t("myCard.maxNum") }}
              {{ couponCurrentCode === "KRW" ? "₩" : couponCurrentCode
              }}{{ item.max_discount_price[couponCurrentCode] }}
            </p>
            <div>
              <div class="c-name">
                <span>{{ item.name }} </span>
                <div class="c-question" @click.stop="showCouponInfo(item)">
                  <img
                    src="@/assets/images/mycard/question-circle.png"
                    alt=""
                  />
                </div>
              </div>
              <p
                v-if="
                  item.price_condition &&
                  item.price_condition[couponCurrentCode]
                "
                class="min-num"
              >
                {{
                  $t("myCard.useType", {
                    amount:
                      couponCurrentCode === "KRW"
                        ? "₩" + item.price_condition[couponCurrentCode]
                        : couponCurrentCode +
                          item.price_condition[couponCurrentCode],
                  })
                }}
              </p>
              <p class="time">
                {{ $t("myCard.expireAt")
                }}{{ formatDate(item.expire_at, "yyyy-MM-dd hh:mm:ss") }}
              </p>
            </div>
            <div class="c-num">
              <p>
                <em class="share-tag" v-if="item.can_share === 1">{{ $t("myCard.share") }}</em>
                <i>{{ 100 - item.discount }}%</i>{{ $t("myCard.disCount") }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <img src="@/assets/images/mycardH5/coupon-empty.png" alt="" />
          <p>{{ $t("myCard.noCoupon") }}</p>
        </div>
        <div class="pop-bottom" v-if="couponList.length > 0">
          <span @click.stop="showPop = false">{{
            $t("toast.comfirmBtn2")
          }}</span>
        </div>
        <div class="c-info" v-if="showInfo">
          <div class="c-main">
            <div class="c-close" @click="showInfo = false">
              <img src="@/assets/images/close.png" alt="" />
            </div>
            <div class="z-title">{{ chooseQuestion.name }}</div>
            <div class="c-content" v-html="chooseQuestion.content"></div>
          </div>
        </div>
      </div>
    </div>

    <toast :message="message" v-if="message.length > 0"></toast>
    <loading v-if="showLoading"></loading>
    <div v-if="showPayStatus" class="fix-bg">
      <div class="choose-status" v-if="payStatus === -99">
        <p>{{ $t("myCard.pay1") }}</p>
        <div class="flex">
          <span @click="closeStatus">{{ $t("toast.comfirmBtn4") }}</span>
          <span class="active" @click="payQuery">{{ $t("myCard.pay2") }}</span>
        </div>
      </div>
      <template v-else>
        <div class="success" v-if="payStatus === 2 || payStatus === 4">
          <img src="@/assets/images/mycard/success.png" alt="" />
          <h4>{{ $t("myCard.pay2") }}</h4>
          <p>{{ $t("myCard.pay5") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
        <div class="fail" v-else>
          <img src="@/assets/images/mycard/fail.png" alt="" />
          <h4>{{ $t("myCard.pay3") }}</h4>
          <p>{{ $t("myCard.pay4") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
      </template>
    </div>
    <!--  冻结优惠券  -->
    <coupon-modal
      v-show="showCouponModal"
      :coupon-order="couponOrder"
      @closeCouponModal="closeCouponModal"
    ></coupon-modal>

    <!--  兑换优惠券  -->
    <exchange
      v-if="showExchange"
      :isH5="true"
      :couponCurrentCode="couponCurrentCode"
      @closeExchangeModal="closeExchangeModal"
      @showCouponInfo="showCouponInfo"
    ></exchange>
  </div>
</template>

<script>
import {
  getCookie,
  getLocalStorage,
  getSessionStorage,
  getToken,
  removeLocalStorage,
} from "@/utils/stage";
import {
  gashCommonOrder,
  myCardWebOrder,
  myCoupon,
  payLetterCustom,
  payLetterOrder,
  payPalCustom,
  payPalWebOrder,
  payQuery,
  queryCouponOrder,
  webItemList,
} from "@/api/mycard";
import CouponModal from "@/views/MyCard/components/couponModal";
import toast from "@/views/MyCard/components/toast";
import loading from "@/views/MyCard/components/loading";
import staticGiftList from "@/views/MyCard/components/giftList";
import Exchange from "@/views/MyCard/components/exchange";

export default {
  name: "orderPay",
  components: {
    toast,
    loading,
    CouponModal,
    Exchange,
  },
  data() {
    return {
      pointPrice: getLocalStorage("pointPrice"),
      chooseProduct: getLocalStorage("chooseProduct")
        ? JSON.parse(getLocalStorage("chooseProduct"))
        : {},
      chooseRoleInfo: getLocalStorage("chooseRoleInfo")
        ? JSON.parse(getLocalStorage("chooseRoleInfo"))
        : {},
      pointNum: getLocalStorage("pointNum"),
      couponCurrentCode: getLocalStorage("couponCurrentCode"),
      hasChooseCouponList: [],
      payWays: [],
      payWayId: "",
      payWay: "",
      payLetterList: [
        {
          name: "naver pay",
          id: "naverpay",
          img: require("@/assets/images/mycard/naverpay.png"),
        },
        {
          name: "payco",
          id: "payco",
          img: require("@/assets/images/mycard/payco.png"),
        },
        {
          name: "kakao pay",
          id: "kakaopay",
          img: require("@/assets/images/mycard/opay.png"),
        },
        {
          name: "信用卡",
          id: "creditcard",
          img: require("@/assets/images/mycard/crad_icon.png"),
        },
      ],
      pg_code: "naverpay",
      pointCustom: {
        // 自定义配置
        addition_num: "",
        currency_code: "",
        exchange_rate: "",
        max_num: "",
        min_num: "",
        pay_point: "",
        currency_code_city: "",
      },
      disabled: "",
      couponList: [],
      showPop: false,
      showInfo: false,
      chooseQuestion: {},
      couponOrder: {},
      giftList: [],
      showCouponModal: false,
      message: "",
      showLoading: false,
      showPayStatus: false,
      payStatus: -99,
      minPrices: {
        CNY: 1,
        KRW: 100,
        TWD: 100,
        JPY: 100,
        HKD: 100,
        USD: 1,
      },
      showExchange: false,
    };
  },
  computed: {
    disCountPrice() {
      let price = "";
      this.hasChooseCouponList.forEach((item) => {
        let maxNum = "";
        if (item.max_discount_price) {
          maxNum = item.max_discount_price[this.couponCurrentCode];
        }
        let str = "";
        if (this.pointPrice) {
          let discountPrice =
            (this.pointPrice * 100 * (100 - item.discount)) / 100 / 100;
          if (this.couponCurrentCode !== "USD") {
            discountPrice = discountPrice.toFixed();
          } else {
            discountPrice = discountPrice.toFixed(2);
          }
          if (maxNum && discountPrice > maxNum) {
            str = maxNum;
          } else {
            str = discountPrice;
          }
        } else {
          if (this.chooseProduct.price) {
            let discountPrice =
              (this.chooseProduct.price * (100 - item.discount)) / 100 / 100;
            if (this.couponCurrentCode !== "USD") {
              // discountPrice = Math.ceil(discountPrice);
            } else {
              discountPrice = discountPrice.toFixed(2);
            }
            if (maxNum && discountPrice > maxNum) {
              str = maxNum;
            } else {
              str = discountPrice;
            }
          }
        }
        price = Number(price) + Number(str);
      });
      if (this.couponCurrentCode !== "USD") {
        price = Math.ceil(price);
      }
      if (this.pointPrice) {
        price >
        this.pointPrice -
          this.minPrices[this.pointCustom.currency_code_city] / 100
          ? (price =
              this.pointPrice -
              this.minPrices[this.pointCustom.currency_code_city] / 100)
          : "";
      } else if (this.chooseProduct.price) {
        price >
        (this.chooseProduct.price -
          this.minPrices[this.chooseProduct.currency_code_city]) /
          100
          ? (price =
              (this.chooseProduct.price -
                this.minPrices[this.chooseProduct.currency_code_city]) /
              100)
          : "";
      }
      return price;
    },
    showPoint() {
      let flag = false;
      if (this.couponList && this.couponList.length > 0) {
        this.couponList.forEach((item) => {
          if (!item.disabled) {
            flag = true;
          }
        });
        if (this.hasChooseCouponList.length > 0) {
          flag = false;
        }
      }
      return flag;
    },
    couponIds() {
      let str = "";
      this.hasChooseCouponList.forEach((item) => {
        str = str + "," + item.id;
      });
      str = str.slice(1);
      return str;
    },
  },
  watch: {
    hasChooseCouponList(val) {
      if (val) {
        let notShare = false;
        this.couponList.forEach((each) => {
          let flag = false;
          val.forEach((item) => {
            if (item.id === each.id) {
              flag = true;
              item.can_share !== 1 ? (notShare = true) : "";
            }
          });
          flag ? (each.active = 1) : (each.active = 0);
        });
        this.setCouponDisabled();
        this.couponList.forEach((each) => {
          if (val.length === 2 && each.active !== 1) {
            each.disabled = true;
          } else {
            if (notShare) {
              if (each.can_share !== 1 && each.active !== 1) {
                each.disabled = true;
              }
            }
          }
        });
        this.$forceUpdate();
      }
    },
  },
  created() {
    this.init();
    this.showPayBtns();
    if (this.chooseProduct.currency_code === "₩") {
      this.chooseProduct.currency_code_city = "KRW";
    } else {
      this.chooseProduct.currency_code_city = this.chooseProduct.currency_code;
    }
  },
  methods: {
    closeExchangeModal() {
      this.showExchange = false;
      this.myCoupon();
    },
    init() {
      if (
        getToken("login") === "success" ||
        getLocalStorage("accountStorage") ||
        getCookie("access_token")
      ) {
        this.webItemList();
        this.myCoupon();
      } else {
        this.$router.push("/");
      }
    },
    webItemList() {
      webItemList({
        role_id: this.chooseRoleInfo.role_id || "",
        project_id: this.chooseRoleInfo.project_id || "",
        payway_id: this.payWayId,
      }).then((res) => {
        if (res.data.custom != null) {
          this.pointCustom = res.data.custom;
          if (
            this.pointCustom.currency_code &&
            this.pointCustom.currency_code === "KRW"
          ) {
            this.pointCustom.currency_code_city =
              this.pointCustom.currency_code;
            this.pointCustom.currency_code = "₩";
          }
        }
        this.giftList = res.data.common;
        this.queryCouponOrder();
      });
    },
    goBack() {
      this.$router.push("/payOnline");
    },
    formatDate(dateNum, fmt) {
      const date = new Date(Number(dateNum) * 1000);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    showPayBtns() {
      this.payWays = JSON.parse(getSessionStorage("configInit")).pay_ways;
      this.payWays.forEach((item) => {
        if (item.type === "15") {
          this.payWayId = item.type;
          this.payWay = "paypal";
        } else if (item.type === "12") {
          this.payWayId = item.type;
          this.payWay = "gash1";
        } else if (item.type === "11") {
          this.payWayId = item.type;
          this.payWay = "mycard1";
        }
      });
      const arr = [];
      this.payWays.forEach((item) => {
        if (item.type === "12") {
          item.name = "gash1";
          item.text = this.$t("myCard.payWay1");
          arr.push(item);
        } else if (item.type === "11") {
          item.name = "mycard1";
          item.text = this.$t("myCard.payWay3");
          arr.push(item);
        } else if (item.type === "15") {
          item.name = "paypal";
          arr.push(item);
        } else if (item.type === "22") {
          item.name = "payletter";
          arr.push(item);
        } else if (item.type === "18") {
          item.name = "payPalCard";
          arr.push(item);
        }
      });
      this.payWays = arr;
      if (this.payWays.length > 0) {
        this.payWayId = this.payWays[0].type;
        this.payWay = this.payWays[0].name;
      }
    },
    choosePayWay(item) {
      this.payWay = item.name;
      this.payWayId = item.type;
    },
    myCoupon() {
      this.couponList = [];
      myCoupon().then((res) => {
        this.couponList = res.data.coupons;
        this.setCouponDisabled();
        this.hasChooseCouponList = [];
      });
    },
    setCouponDisabled() {
      // this.couponCurrentCode = "KRW";
      this.couponList.forEach((coupon) => {
        if (this.pointPrice && this.pointPrice > 0) {
          // 有最低使用门槛
          if (
            coupon.price_condition &&
            coupon.price_condition[this.couponCurrentCode]
          ) {
            if (
              this.pointPrice <
              Number(coupon.price_condition[this.couponCurrentCode])
            ) {
              coupon.disabled = true;
            } else {
              coupon.disabled = false;
            }
          } else {
            coupon.disabled = false;
          }
        } else {
          // 是虹晶
          if (this.chooseProduct.productType != "80" && coupon.type === 1) {
            coupon.disabled = true;
          } else {
            // 有最低使用门槛
            if (
              coupon.price_condition &&
              coupon.price_condition[this.couponCurrentCode]
            ) {
              if (
                this.chooseProduct.price &&
                this.chooseProduct.price / 100 <
                  Number(coupon.price_condition[this.couponCurrentCode])
              ) {
                coupon.disabled = true;
              } else {
                coupon.disabled = false;
              }
            } else {
              coupon.disabled = false;
            }
          }
        }
      });
    },
    showCouponInfo(item) {
      item.content = item.content.replace(/\n/g, "</br>");
      this.chooseQuestion = item;
      this.showInfo = true;
    },
    chooseCoupon(item) {
      let flag = false;
      this.hasChooseCouponList.forEach((each, index) => {
        if (each.id === item.id) {
          flag = true;
          this.hasChooseCouponList.splice(index, 1);
        }
      });
      if (!item.disabled) {
        if (!flag && this.hasChooseCouponList.length < 2) {
          this.hasChooseCouponList.push(item);
        }
      }
    },
    closeCouponModal() {
      this.showCouponModal = false;
      this.couponOrder = {};
    },
    closeStatus() {
      if (this.payStatus === 2 || this.payStatus === 4) {
        this.$router.push("/payOnline");
      }
      this.showPayStatus = false;
      this.payStatus = -99;
      this.myCoupon();
    },
    payQuery() {
      payQuery({ out_trade_no: this.out_trade_no }).then((res) => {
        this.payStatus = res.data.status || 0;
        if (this.payStatus === 2 || this.payStatus === 4) {
          removeLocalStorage("chooseProduct");
          removeLocalStorage("pointPrice");
          removeLocalStorage("pointNum");
          removeLocalStorage("pointExtraNum");
          this.closeCouponModal();
        }
        this.myCoupon();
      });
    },
    queryCouponOrder() {
      this.showLoading = true;
      queryCouponOrder()
        .then((res) => {
          this.showLoading = false;
          this.couponOrder = {};
          if (
            res.data.trade.project_id &&
            res.data.trade.project_id == getLocalStorage("projectId")
          ) {
            this.couponOrder = res.data.trade;
            if (this.couponOrder.product_id == 99999999) {
              this.couponOrder.product_subject = this.$t("myCard.extraTitle");
            } else {
              Object.values(this.giftList).forEach((list) => {
                list.forEach((inner) => {
                  inner.products.forEach((item) => {
                    staticGiftList.forEach((gift) => {
                      if (gift.product_id == item.product_id) {
                        if (this.isEn) {
                          item.product_subject = gift.product_subject_en;
                        } else if (this.isKr) {
                          item.product_subject = gift.product_subject_kr;
                        } else {
                          item.product_subject = gift.product_subject;
                        }
                        if (!gift.icon) {
                          item.icon = gift.product_id;
                        }
                      }
                    });
                    // }
                    // productType = 305 or 306 名字写死
                    if (item.productType === 305) {
                      item.product_subject = "限時秒殺禮包";
                    } else if (item.productType === 306) {
                      item.product_subject = "金喵賜福禮包";
                    }
                    if (item.product_id == this.couponOrder.product_id) {
                      this.couponOrder.product_subject = item.product_subject;
                    }
                  });
                });
              });
            }
          }
        })
        .catch((error) => {
          this.showLoading = false;
          this.message = error.message;
        });
    },
    submit() {
      if (!this.disabled) {
        if (this.couponOrder.product_id) {
          this.showCouponModal = true;
        } else {
          this.showLoading = true;
          let obj = {
            product_id: this.chooseProduct.product_id,
            product_subject: this.chooseProduct.product_subject,
            amount: this.chooseProduct.price,
            game_app_id: this.chooseRoleInfo.game_app_id,
            // trade_type: "2",
            // payment_type: "COSTPOINT",
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            user_coupon_id: this.couponIds,
            mobile: 1, // 0:PC 1:H5
          };

          let url = window.location.origin + "/loading";
          let winOpen = window.open(url, "");
          if (this.payWay === "gash1") {
            gashCommonOrder(obj)
              .then((res) => {
                this.showLoading = false;
                this.showPayStatus = true;
                if (res.data.transaction_url) {
                  this.out_trade_no = res.data.out_trade_no;
                  this.gashUrl = res.data.transaction_url;
                  this.gashData = res.data.auth_code;
                  let formData = `<form name="form1" id="form1" action=${this.gashUrl} method="post">
                  <input type="hidden" name="data" value=${this.gashData}>
                  <input type="submit" value="send">
                </form>`;

                  let newWindow = window.open("", "");
                  newWindow.document.write("<html><head></head><body>");
                  let div = newWindow.document.createElement("div");
                  div.innerHTML = formData;
                  newWindow.document.body.appendChild(div);
                  div.style.display = "none";
                  newWindow.document.form1.submit();
                } else {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = this.$t("myCard.failed");
                }
              })
              .catch((error) => {
                winOpen.close();
                this.showLoading = false;
                this.message = error.message;
              });
          } else if (this.payWay === "paypal") {
            if (this.pointNum && this.pointNum > 0) {
              let payPalObj = Object.assign({}, obj);
              payPalObj.amount = this.pointNum;
              payPalObj.product_id = 99999999;
              payPalObj.product_subject = this.$t("myCard.extraTitle");
              payPalCustom(payPalObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    // window.open(res.data.transaction_url, "catPay");
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            } else {
              payPalWebOrder(obj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    // window.open(res.data.transaction_url, "windowView");
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            }
          } else if (this.payWay === "payletter") {
            const payLetterObj = Object.assign({ pg_code: this.pg_code }, obj);
            if (this.pointNum && this.pointNum > 0) {
              payLetterObj.product_id = 99999999;
              payLetterObj.product_subject = this.$t("myCard.extraTitle");
              payLetterObj.amount = this.pointNum;
              payLetterCustom(payLetterObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    // window.open(res.data.transaction_url, "catPay");
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            } else {
              payLetterOrder(payLetterObj)
                .then((res) => {
                  this.showLoading = false;
                  this.showPayStatus = true;
                  if (res.data.transaction_url) {
                    this.out_trade_no = res.data.out_trade_no;
                    // window.location.href = res.data.transaction_url;
                    // window.open(res.data.transaction_url, "catPay");
                    setTimeout(() => {
                      winOpen.location = res.data.transaction_url;
                    }, 200);
                  } else {
                    winOpen.close();
                    this.showLoading = false;
                    this.message = this.$t("myCard.failed");
                  }
                })
                .catch((error) => {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = error.message;
                });
            }
          } else {
            myCardWebOrder(obj)
              .then((res) => {
                this.showLoading = false;
                this.showPayStatus = true;
                if (res.data.transaction_url) {
                  this.out_trade_no = res.data.out_trade_no;
                  // window.location.href = res.data.transaction_url;
                  // window.open(res.data.transaction_url, "catPay");
                  setTimeout(() => {
                    winOpen.location = res.data.transaction_url;
                  }, 200);
                } else {
                  winOpen.close();
                  this.showLoading = false;
                  this.message = this.$t("myCard.failed");
                }
              })
              .catch((error) => {
                winOpen.close();
                this.showLoading = false;
                this.message = error.message;
              });
          }
        }
      }
    },
  },
};
</script>
